<template>
      <v-card id="card-modal-modal-download" class="elevation-4">
        <v-card-title class="justify-center py-4 card-modaltitle">
          <div class="pl-4">
              <h4 class="title-card">
                <v-icon> fa-solid fa-file-excel</v-icon>
                <span>{{ $t("views.reports.availability.table.download_excel") }}</span>
              </h4>
          </div>

          <v-spacer />
          <v-btn depressed text color="primary" @click.stop="$emit('closeForm')" >
            <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-form v-model="valida" dense>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>{{ $t("views.reports.availability.table.download_excel_text") }}</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-2 pb-0">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        :label="$t('views.reports.availability.table.download_excel_range')"
                        append-icon="fa-regular fa-calendar-days"
                        readonly
                        dense
                        outlined
                        hide-details
                        required
                        :rules="[
                          required($t('views.reports.availability.table.download_excel_range')),
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                      <v-date-picker v-model="dateDownload" locale="es" range />

                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="border-top my-0">
          <v-spacer />
          <v-btn
            color="light-green darken-3"
            :disabled="!valida"
            depressed
            class="white--text"
            @click="onGenerateExcel"
          >
            <v-icon small class="fa-solid fa-file-excel mr-1" />
            {{ $t("views.reports.availability.table.download_excel") }}
          </v-btn>
          <v-btn depressed @click.stop="$emit('closeForm')">
            <v-icon small size="15" class="mr-1"> fa-solid fa-xmark</v-icon>
            {{ $t("components.app.datatable.exit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validate from "@/plugins/validate";

export default {
  data() {
    return {
      ...validate,
      modalDownload: false,
      idMonitoringOrder: 0,
      index: 0,
      valida: false,
      dialogDetails: false,
      dateDownload: [],
      menu: false,
    };
  },
  name: "ModalDownload",
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    dateRangeText() {
      return this.dateDownload.join(" A ");
    },
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    url() {
      return `/Offers/ReportsToDrivers`;
    },
    urlCount() {
      return `/Offers/TotalReportsToDrivers`;
    },
    searchField() {
      return this.search;
    },

  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.status_of_offers"),
    };
  },
  watch: {
    dateDownload(newVal) {
      if (newVal[1] !== undefined) {
        let date1 = this.moment(newVal[0]),
          date2 = this.moment(newVal[1]);
        let diff = Math.abs(date2.diff(date1, "days"));
        if (diff > 30) {
          this.showSnack({
            text: this.$t("views.reports.availability.table.download_excel_range_title"),
            title: this.$t("views.reports.availability.table.download_excel_range_message"),
            name: "warning",
          });
        }
      }
    },
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("snackbar", ["showSnack"]),


    async onGenerateExcel() {
      const date = this.dateDownload;

      this.showLoader({ show: true });

      date.forEach((element, index) => {
        date[index] = this.moment(element, "YYYY-MM-DD").utc().format("YYYY-MM-DD HH:mm");
      });
      if (date[1] === undefined) date[1] = date[0];

      const formData = new FormData();
      const dateStart = this.dateDownload[0];
      const dateEnd = this.dateDownload[1];
      const search = this.searchField;

      formData.append("dates", dateStart.toString());
      formData.append("dates", dateEnd.toString());
      formData.append("search", search);

      await this.axios({
        url: "/Offers/ExportExcel",
        method: "POST",
        responseType: "arraybuffer",
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data;`
        }
      })
        .then((response) => {
          const nameFile = `${this.$t(
            "components.layout.sidebar.availability"
          )}_${this.dateDownload.toString()}.xlsx`;
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob),
            link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", nameFile);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {

        })
        .finally(() => {
          this.showLoader({ show: false });
        });
    },
  },
};
</script>
<style scoped>
#card-modal-modal-download {
  border-radius: 12px;
}

.card-modaltitle {
    border-bottom: 1px solid #ccc;
}

</style>
